import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Img from "gatsby-image"
import Link from 'gatsby-link';
import LazyLoad from 'react-lazyload';
import SectionCard from './SectionCard';
import archivoIMG from '../assets/defaults/archivo.png';

import Autor from './Autor.js';

const useStyles = makeStyles(theme => ({
    card: {
      //marginBottom: "1rem",
    },
    cardContent: {
      marginLeft:"-1rem",
      paddingBottom:'0rem !important',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      [theme.breakpoints.down('sm')]: {
        //width: "100%",
        //paddingTop:'0rem',
        maxHeight: "215px",
      }
    },
    boxIMG:{
      position:"relative",
    },
    excerpt:{
      marginTop:"0.8rem !important",
      color:"black",
      fontSize:"1.1rem !important",
      lineHeight:"1.5rem !important",
      [theme.breakpoints.down('md')]: {
        fontSize:"1.0rem !important",
        lineHeight:"1.4rem !important",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.9rem !important",
        lineHeight:"1.3rem !important",
      },
      //textShadow: "1px 1px #000",
    },
    escribe:{
      marginTop:"0.8rem !important",
      color:"black",
      fontWeight:"bold !important",
      fontSize:"0.9rem !important",
      [theme.breakpoints.down('md')]: {
        fontSize:"0.8rem !important",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.7rem !important",
      },
      //textShadow: "1px 1px #000",
    },
    tituloNota:{
      color:"black",
      fontFamily:"FuturaBold",
      textDecoration:"none",
      [theme.breakpoints.down('md')]: {
        lineHeight:"2.3rem"
      },
      [theme.breakpoints.down('sm')]: {
        lineHeight:"2rem"
      },
      fontSize:"1.7rem",
    },
  }));

export default function NotaCard(props) {
  // let showSection = true;
  const { node, imgFluidDefault, showSection, showImage } = props
  // console.log("NOTA CARD ",node.frontmatter.image);
  const classes = useStyles()
  const esRevista = node.frontmatter.seccion === 'revista'

  return (
    <Card className={classes.card}>
      <Box className={classes.boxIMG}>
        {
          (node.frontmatter.image || node.frontmatter.seccion === 'archivo') && showImage && (
            <Link to={`${esRevista ? '/revista':''}/${node.fields.slug}`}>
              <LazyLoad height={215}>
                <CardMedia
                  className={classes.media}
                  image={node.frontmatter.seccion === 'archivo' ? archivoIMG : `https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/${node.frontmatter.image}`}
                  title={node.frontmatter.title}
                />
              </LazyLoad>
            </Link>
          )
        }
        {
          node.frontmatter.image === null && node.frontmatter.seccion !== 'archivo' && <Img fluid={imgFluidDefault} className={classes.media} />
        }
        {showSection && <SectionCard label={node.frontmatter.seccion} />}
      </Box>
      <CardContent className={classes.cardContent}>
        <Link className={classes.tituloNota} to={`${esRevista ? '/revista':''}/${node.fields.slug}`}>
          {node.frontmatter.title}
        </Link>
        <Typography className={classes.excerpt} component="p">
          {node.frontmatter.subhead ? node.frontmatter.subhead : ''}
        </Typography>
        <Autor names={node.frontmatter.author} />
      </CardContent>
    </Card>
  );
}

NotaCard.defaultProps = {
  node: {
    frontmatter: {},
    fields: {}
  },
  showSection: true,
  showImage: true,
}
