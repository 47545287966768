import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import moment from 'moment';
import SectionCard from './SectionCard';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import archivoIMG from '../assets/defaults/archivo.png';

import Autor from './Autor.js';

const { slug } = require('../utils/tools.js');
const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    textDecoration: 'none'
  },
  'card-horizontal': {
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  'card-vertical': {
    flexDirection: 'column'
  },
  hidden: {
    display: 'none'
  },
  cardContent: {
    color:"black",
    textAlign: 'left',
    padding: '0 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  cardMedia: {
    flexBasis: '300px',
    minWidth: '300px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '200px',
      minWidth: '200px',
      marginBottom: '1rem'
    }
  },
  media: {
    width: '100%',
    height: 0,
    paddingTop: '56.25%', // 16:9
    [theme.breakpoints.down('sm')]: {
      paddingTop: '75%', // 4:3
    }
  },
  title:{
    fontSize:"1.7rem",
    fontFamily:"FuturaBold",
    marginTop:"1rem",
    [theme.breakpoints.down('md')]: {
      lineHeight:"2.3rem"
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight:"2rem"
    }
  },
  excerpt:{
    fontSize: "1.1rem",
    marginTop: "0.8rem",
    lineHeight: "1.5rem"
  },
  fecha:{
  },
}));

const Nota = props => {
  const { node, imgFluidDefault, flow, showSeccion, showImage } = props;
  const classes = useStyles();
  const source = node.frontmatter.image
    ? `https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/${node.frontmatter.image}`
    : imgFluidDefault.src;

  // TODO cuando haya una recuperacion de imagenes de otras notas q no sean las de jorge
  // sacaremos el cosito de archivo para las notas que si tienen imagen y quedaria solo default

  return (
    <Card>
      <Link to={`${node.frontmatter.seccion === "revista" ? "/revista" : ""}/${node.fields.slug}`} className={`${classes.card} ${classes[`card-${flow}`]}`}>
        {
          showImage && (
            <div className={classes.cardMedia}>
              <CardMedia
                component="div"
                className={classes.media}
                image={node.frontmatter.seccion === 'archivo' ? archivoIMG : source}
                title={node.frontmatter.title}
                alt={node.frontmatter.title}
              />
              {showSeccion && <SectionCard label={node.frontmatter.seccion} />}
            </div>
            )
          }
        <CardContent className={classes.cardContent}>
          <span className={classes.fecha}>{moment( moment.utc(node.frontmatter.date)).format('DD/MM/YYYY')}</span>
          <Typography className={classes.title} gutterBottom variant="h5" component="h2">
            {node.frontmatter.title}
          </Typography>
          <Typography variant="body2" className={classes.excerpt} component="p">
            {node.frontmatter.subhead}
          </Typography>

          <Autor names={node.frontmatter.author} />
        </CardContent>
      </Link>
    </Card>
  );
};

Nota.propTypes = {
  flow: PropTypes.oneOf([ 'vertical', 'horizontal' ]),
  showSeccion: PropTypes.bool
};

Nota.defaultProps = {
  flow: 'horizontal',
  showSeccion: false,
  showImage: true,
};

export default Nota;
