import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

const useStyles = makeStyles(theme => ({
    container: {
      // [theme.breakpoints.down('sm')]: {
      width: '100%',
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: "80%",
      // },
    },  
    card: {
      margin: "1rem 0rem",
    },
    cardContent: {
      textAlign: "center",
      padding:"0",
      fontFamily:"FuturaBold",
      marginTop:"0.4rem !important",
      color:"black !important",
      fontSize:"0.9rem !important",
      lineHeight:"1.2rem !important",
      textDecoration:"none",
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.8rem !important",
      },
    },
    NoLink: {
      color: "inherit", /* blue colors for links too */
      textDecoration: "inherit", /* no underline */
    },
    video: {
      [theme.breakpoints.up('md')]: {
        width: 330,
        height: 215,
      },
      [theme.breakpoints.up('xl')]: {
          width: 420,
          height: 300,
      },
    },
    dot: {
      display: 'inline-block',
      height: '15px',
      width: '15px',
      backgroundColor: 'black',
      borderRadius: '50%',
      marginRight: '5px',
    },
  })
);

const VideoCarousel = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Carousel
        renderIndicator={
          (onClickHandler, isSelected, index, label) => {
            return (
              <span
                className={classes.dot}
                style={isSelected ? {backgroundColor: 'red'} : {}}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={`carousel-video-dot-${index}`}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              >
              </span>
            )
          }
        }
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
      >    
        {
          data.map( (i, index) => {
            return(
              <div key={`carousel-video-item-${index}`}>
                <Card className={classes.card}>
                  <iframe className={classes.video} src={i.link} title={i.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  <CardContent className={classes.cardContent}>
                    <a className={classes.NoLink} href={i.link} target="_blank" rel="noopener norefferer">
                      {i.title}
                    </a>
                  </CardContent>
                </Card>
              </div>
            )
          })
        }
      </Carousel>
    </div>
  );
}

export default VideoCarousel;
