import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Img from "gatsby-image"
import Link from 'gatsby-link';
import LazyLoad from 'react-lazyload';
import { graphql, useStaticQuery } from "gatsby"
const { slug } = require('../utils/tools.js');

const useStyles = makeStyles(theme => ({
    card: {
      margin: "1.4rem 1rem",
      display:"flex",
      [theme.breakpoints.down('lg')]: {
        margin: "1rem 0",
      },
      [theme.breakpoints.down('md')]: {
        width:"50%",
        flex: '1 1 20rem',
      },
      [theme.breakpoints.down('sm')]: {
        margin: "2rem 0",
      }
    },
    cardContent: {
      width:"50%",
      marginLeft:"0.5rem",
      padding:"0",
      [theme.breakpoints.down('sm')]: {
        width:"60%",
      },
    },
    media: {
      height:"9.5rem",
      width:"9.5rem",
      borderRadius:"50%",
      [theme.breakpoints.down('lg')]: {
        height: "7rem",
        width:"7rem",
      },
      [theme.breakpoints.down('sm')]: {
        height: "6rem",
        width:"6rem",
      },
    },
    seccionTitle:{
      color: "black",
      fontWeight: "bold",
      fontSize: "0.9rem",
      marginBottom: "0.5rem"
    },
    tituloNota:{
      fontFamily:"FuturaBold",
      marginTop:"0.4rem !important",
      color:"black !important",
      fontSize:"0.9rem !important",
      lineHeight:"1.2rem !important",
      textDecoration:"none",
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.8rem !important",
      },
    },
  })
);

const NotaCardChica = ({ node, imgFluidDefault }) => {
  const classes = useStyles();
  const secciones = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          secciones
        }
      }
    }
  `).site.siteMetadata.secciones;

  const seccionName = node.frontmatter.seccion === "revista" ? "POLÍTICA OBRERA REVISTA" : secciones.filter(item => slug(item) === node.frontmatter.seccion)[0];
  const esRevista = node.frontmatter.seccion === 'revista'

  return (
    <Card className={classes.card}>
      {
        node.frontmatter.image && (
          <Link to={`${esRevista ? 'revista':''}/${node.fields.slug}`}>
            <LazyLoad height={128}>
              <CardMedia
                className={classes.media}
                image={`https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/${node.frontmatter.image}`}
                title={node.frontmatter.title}
              />
            </LazyLoad>
          </Link>
        )
      }
      {
        node.frontmatter.image === null && <Img fluid={imgFluidDefault} className={classes.media} />
      }
      <CardContent className={classes.cardContent}>
        <Typography className={classes.seccionTitle} component="p">
          {seccionName}
        </Typography>
        <Link className={classes.tituloNota} to={`${esRevista ? 'revista':''}/${node.fields.slug}`}>
          {node.frontmatter.title}
        </Link>
      </CardContent>
    </Card>
  );
}

export default NotaCardChica;
