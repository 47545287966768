import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import SectionCard from './SectionCard';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Img from "gatsby-image"
import Link from 'gatsby-link';
import LazyLoad from 'react-lazyload';

import Autor from './Autor.js';

const useStyles = makeStyles(theme => ({
    card: {
      position: 'relative'
    },
    cardHeader:{
      position: 'absolute',
      zIndex: 2,
      top: '20rem',
      left: '0rem',
      color: 'white'
    },
    cardContent: {
      position: 'absolute',
      zIndex: 2,
      bottom: '1rem',
      left: '2rem',
      width:"60%",
      [theme.breakpoints.down('sm')]: {
        padding:'0 !important',
        left:'0.5rem',
        bottom:'0.5rem'
      }
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      position: 'relative',
      zIndex: 1,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        background: 'linear-gradient(to bottom, transparent 0%, black 100%)',
        fallbacks: {
          background: 'red'
        },
        width: '100%',
        opacity: '.8',
        top: 0,
        bottom: 0
      }
    },
    excerpt:{
      marginTop:"0.8rem !important",
      color:"#f0f0f1 !important",
      fontSize:"0.9rem !important",
      [theme.breakpoints.down('md')]: {
        fontSize:"1.0rem !important",
        lineHeight:"1.4rem !important"
      },
      [theme.breakpoints.down('sm')]: {
        display:'none'
      }
    },
    // escribe:{
    //   marginTop:"0.8rem !important",
    //   color:"#f0f0f1 !important",
    //   fontSize:"0.5rem !important",
    //   [theme.breakpoints.down('md')]: {
    //     fontSize:"0.8rem !important"
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     display:'none'
    //   }
    // },
    tituloNota:{
      color:"white !important",
      fontSize:"2.6rem !important",
      fontFamily:"FuturaBold",
      lineHeight:"2.6rem !important",
      textDecoration:"none",
      [theme.breakpoints.up('md')]: {
        lineHeight:"3rem !important",
        fontSize:"2.3rem !important"
      },
      [theme.breakpoints.down('md')]: {
        lineHeight:"2.7rem !important",
        fontSize:"2rem !important"
      },
      [theme.breakpoints.down('sm')]: {
        lineHeight:"1.5rem !important",
        fontSize:"1.4rem !important",
      }
    }
  })
);

export default function NotaCardBig(props) {

  const { node, imgFluidDefault} = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const esRevista = node.frontmatter.seccion === 'revista'
  
  if (node) {
    const tituloNota = matches && node.frontmatter.title.length > 51 ? node.frontmatter.title.substring(0,50)+'\u2026' : node.frontmatter.title;
    return (
      <Card className={classes.card}>
        <SectionCard label={node.frontmatter.seccion} />
        <CardContent className={classes.cardContent}>
          <Link className={classes.tituloNota} to={`${esRevista ? 'revista':''}/${node.fields.slug}`}>
            {tituloNota}
          </Link>
  
          <Typography className={classes.excerpt} component="p">
            {node.frontmatter.subhead ? node.frontmatter.subhead : ''}
          </Typography>
  
          <Autor names={node.frontmatter.author} theme="light" />
        </CardContent>
        {
          node.frontmatter.image && (
            <Link  to={`${esRevista ? 'revista':''}/${node.fields.slug}`}>
              <LazyLoad height={590}>
                <CardMedia
                  className={classes.media}
                  image={`https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/${node.frontmatter.image}`}
                  title={node.frontmatter.title}
                />
              </LazyLoad>
            </Link>
          )
        }
        {
          node.frontmatter.image === null && <Img fluid={imgFluidDefault} className={classes.media} />
        }
      </Card>
    );
  } else {
    return null;
  }
}

